<template>
  <div class="about-header">
    <div class="logo-wrapper">
      <BRIcon
        :width="$vuetify.breakpoint.xs ? '257' : '394'"
        class="logo"
        fill-color="var(--logo-color)"
        icon="logo"
      />
    </div>
    <div class="search-wrapper">
      <div class="search">
        <BRInput
          :append-icon="$vuetify.breakpoint.xs ? 'mdi-close' : ''"
          :value.sync="searchValue"
          background-color="var(--content-bg-color)"
          class="search-input"
          placeholder="Find the cryptocurrency or ICO"
          @click:append="searchValue = ''"
        >
          <BRIcon
            height="24"
            width="24"
            class="search-icon"
            color="var(--default-icon-color)"
            icon="search"
          />
        </BRInput>
        <v-select
          v-model="searchSelectedItem"
          :height="$vuetify.breakpoint.xs ? '40' : '60'"
          :items="searchSelectItems"
          :menu-props="{ bottom: true, offsetY: true }"
          background-color="var(--content-bg-color)"
          class="search-select"
          dense
          flat
          hide-details
          item-color="var(--primary-text-color)"
          label="Cryptocasinos"
          reverse
          solo
        />
      </div>
      <BRButton
        :width="$vuetify.breakpoint.xs ? '88' : '140'"
        class="search-button"
        dark
        @click="redirectToSearch"
      >
        SEARCH
      </BRButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutHeader',
  data() {
    return {
      searchValue: '',
      searchSelectedItem: 'Cryptocasinos',
      searchSelectItems: ['Cryptocasinos']
    }
  },
  methods: {
    redirectToSearch() {
      this.$router.push({
        name: 'CasinoCurrency',
        params: { slug: 'crypto' },
        query: { keyword: this.searchValue }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.logo-wrapper {
  display: flex;
  margin: -28px -20px 0;
  height: 456px;
  background-image: url("/img/about-bg-mobile.svg");
  background-position: 50%;
  background-size: 600px;

  .logo {
    position: relative;
    top: 20px;
    margin: auto;
    height: 50px;

    @include breakpoint(medium) {
      height: 75px;
    }
  }

  @include breakpoint(medium) {
    background-image: url("/img/about-bg-desktop.svg");
    background-size: 1500px;
  }

  @include breakpoint(large) {
    position: relative;
    left: 50%;
    margin-top: -50px;
    margin-left: -50vw;
    width: 100vw;
  }
}

.search-wrapper {
  display: flex;
  gap: 10px;

  .search {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-default);
    padding: 12px 14px;
    width: 100%;
    height: 40px;
    box-shadow: var(--box-shadow-light);
    background-color: var(--content-bg-color);

    @include breakpoint(medium) {
      padding: 18px 30px;
      height: 60px;
    }
  }

  .search-input {
    width: 100%;
    height: 40px;

    &::v-deep .v-input__control > .v-input__slot {
      padding: 0;
      height: 40px !important;
      gap: 10px;

      @include breakpoint(medium) {
        height: 60px !important;
      }
    }

    &::v-deep .v-input__prepend-inner {
      padding: 0;
      font-size: 0;
    }

    &::v-deep .v-input__append-inner {
      width: 20px;

      .v-icon {
        font-size: var(--font-icon);
      }
    }

    @include breakpoint(medium) {
      height: 60px;
    }
  }

  .search-icon {
    margin-right: 6px;
  }

  .search-select {
    position: relative;
    display: none;
    padding-left: 30px;
    max-width: 210px;

    &::before {
      content: "";
      position: absolute;
      top: 13px;
      z-index: 10;
      width: 1px;
      height: 34px;
      background-color: var(--default-icon-color);
      opacity: 0.3;
    }

    &::v-deep {
      .theme--light.v-label,
      .mdi::before,
      .mdi-set {
        color: var(--text-color);
      }

      .v-label,
      .v-select__selection {
        margin-left: 22px;
        width: 100%;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-base);
        color: var(--text-color);
      }

      .v-input__append-inner {
        width: 10px;
      }
    }

    @include breakpoint(medium) {
      display: flex;
    }
  }

  .search-button {
    height: 40px !important;

    &::v-deep .button-text {
      font-size: var(--font-base);
    }

    @include breakpoint(medium) {
      height: 60px !important;
    }
  }

  @include breakpoint(medium) {
    gap: 20px;
  }
}
</style>
