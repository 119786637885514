<template>
  <div class="about-us">
    <AboutHeader class="about-header" />
    <AboutMain />
  </div>
</template>

<script>
import AboutHeader from '@/components/AboutHeader'
import AboutMain from '@/components/AboutMain'

export default {
  name: 'About',
  components: {
    AboutHeader,
    AboutMain
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.about-us {
  @include container;
}

.about-header {
  margin-bottom: 52px;

  @include breakpoint(medium) {
    margin-bottom: 70px;
  }
}
</style>
