<template>
  <div class="about-main">
    <div class="features">
      <div>
        <h2 class="about-title">
          We will fu*k the industry!
        </h2>
        <p class="about-subtitle">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Aut dolore soluta quibusdam reiciendis, quaerat reprehenderit a?
        </p>
      </div>
      <div class="features-cards-wrapper">
        <div
          v-for="card in featuresCards"
          :key="card.id"
          class="features-card"
        >
          <div class="icon-container">
            <BRIcon
              :height="$vuetify.breakpoint.xs ? '54' : '64'"
              :icon="card.image"
              :width="$vuetify.breakpoint.xs ? '54' : '64'"
              fill-color="var(--text-color)"
            />
          </div>
          <div>
            <h3 class="card-title">
              {{ card.title }}
            </h3>
            <p class="card-text">
              {{ card.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="casinos">
      <div>
        <h2 class="about-title">
          How it works
        </h2>
        <p class="about-subtitle">
          Just small description to tell your customers what you will see on this page and how it work with crypto
          casinos bla bla… will see on this page and how it work with crypto bla bla…
        </p>
        <CasinosList
          :is-need-header="false"
          :page-size="$vuetify.breakpoint.xs ? 3 : 2"
          class="casinos"
          is-limit-casinos
          is-open-casino
        />
      </div>
    </div>
    <div class="about-info">
      <h2 class="about-title details-title">
        Some title
      </h2>
      <p class="about-subtitle">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda hic in consequatur,
        sapiente, atque tempore natus numquam maxime ad itaque voluptates saepe!
        Magnam veniam perferendis tempora eum reiciendis eaque recusandae?
      </p>
      <div class="features-cards-wrapper details-card-wrapper">
        <div
          v-for="card in featuresCards"
          :key="card.id"
          class="features-card mobile"
        >
          <div>
            <h3 class="card-title">
              {{ card.title }}
            </h3>
            <p class="card-text">
              {{ card.text }}
            </p>
          </div>
        </div>
        <DetailsCard
          v-for="(item, i) in detailsItems"
          :key="i"
          :item="item"
          class="desktop detail-card"
        />
      </div>
      <p class="about-subtitle">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda hic in consequatur,
        sapiente, atque tempore natus numquam maxime ad itaque voluptates saepe!
        Magnam veniam perferendis tempora eum reiciendis eaque recusandae?
      </p>
    </div>
    <div class="overview review-example">
      <div class="content">
        <h2 class="about-title">
          Fast Overview
        </h2>
        <p class="about-subtitle">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo omnis, totam dolorem
          temporibus porro dicta excepturi saepe aspernatur voluptates officiis,
          quam vel, sed tempora nemo ipsam corporis necessitatibus accusantium delectus.
        </p>
        <div class="btn-container">
          <BRButton
            :width="$vuetify.breakpoint.xs ? '270' : '170'"
            class="content-button"
            dark
            href="/"
          >
            CHECK REVIEWS
          </BRButton>
        </div>
      </div>
      <div class="img-container">
        <picture v-if="getIsDarkTheme">
          <source
            media="(min-width: 768px)"
            srcset="img/about-page/dark/pross.webp 1x,
            img/about-page/dark/pross@2x.webp 2x,
            img/about-page/dark/pross@3x.webp 3x"
            width="425"
          >
          <source
            media="(max-width: 767px)"
            srcset="img/about-page/dark/prossmb.webp 1x,
            img/about-page/dark/prossmb@2x.webp 2x,
            img/about-page/dark/prossmb@3x.webp 3x"
            width="100%"
          >
          <img alt="Pross" src="img/about-page/dark/pross.webp" width="425">
        </picture>
        <picture v-else>
          <source
            media="(min-width: 768px)"
            srcset="img/about-page/light/pross.webp 1x,
            img/about-page/light/pross@2x.webp 2x,
            img/about-page/light/pross@3x.webp 3x"
            width="425"
          >
          <source
            media="(max-width: 767px)"
            srcset="img/about-page/light/prossmb.webp 1x,
            img/about-page/light/prossmb@2x.webp 2x,
            img/about-page/light/prossmb@3x.webp 3x"
            width="100%"
          >
          <img alt="Pross" src="img/about-page/light/pross.webp" width="425">
        </picture>
      </div>
    </div>
    <div class="overview airdrop-calendar">
      <div class="content airdrop">
        <h2 class="about-title">
          ICO calendar
        </h2>
        <p class="about-subtitle">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo omnis, totam dolorem
          temporibus porro dicta excepturi saepe aspernatur voluptates officiis,
          quam vel, sed tempora nemo ipsam corporis necessitatibus accusantium delectus.
        </p>
        <ul class="airdrop-list">
          <li v-for="item in 2" :key="item">
            <span class="airdrop-item">Air Drops calendar</span>
          </li>
        </ul>
      </div>
      <div class="img-container">
        <picture v-if="getIsDarkTheme">
          <source
            media="(min-width: 768px)"
            srcset="img/about-page/dark/calen.webp 1x,
            img/about-page/dark/calen@2x.webp 2x,
            img/about-page/dark/calen@3x.webp 3x"
            width="396"
          >
          <source
            media="(max-width: 767px)"
            srcset="img/about-page/dark/calen.webp 1x,
            img/about-page/dark/calen@2x.webp 2x,
            img/about-page/dark/calen@3x.webp 3x"
            width="100%"
          >
          <img alt="Calendar" src="img/about-page/dark/calen.webp" width="396">
        </picture>
        <picture v-else>
          <source
            media="(min-width: 768px)"
            srcset="img/about-page/light/calen.webp 1x,
            img/about-page/light/calen@2x.webp 2x,
            img/about-page/light/calen@3x.webp 3x"
            width="396"
          >
          <source
            media="(max-width: 767px)"
            srcset="img/about-page/light/calenmb.webp 1x,
            img/about-page/light/calenmb@2x.webp 2x,
            img/about-page/light/calenmb@3x.webp 3x"
            width="100%"
          >
          <img alt="Calendar" src="img/about-page/light/calen.webp" width="396">
        </picture>
      </div>
    </div>
    <div
      v-if="!getUserDB"
      class="features-card login-container"
    >
      <div>
        <h3 class="card-title">
          Start using blockreviews!
        </h3>
        <p class="card-text">
          Just small description to tell your customers what you will
          see on this page and how it work with crypto bla bla… will see on this
        </p>
      </div>
      <BRButton
        :height="$vuetify.breakpoint.xs ? '35' : '50'"
        :to="{
          name: 'Login',
          query: { redirect: $route.path }
        }"
        :width="$vuetify.breakpoint.xs ? '100%' : '218'"
        dark
      >
        CREATE ACCOUNT
      </BRButton>
    </div>
  </div>
</template>

<script>
import DetailsCard from '@/components/DetailsCard'
import CasinosList from './CasinosList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AboutMain',
  components: {
    DetailsCard,
    CasinosList
  },
  data() {
    return {
      featuresCards: [
        {
          id: 1,
          image: 'stick-growth',
          title: 'Easy to use',
          text: 'Just small description to tell your customers what you will see on this page and how it work with crypto bla bla… will see on this'
        },
        {
          id: 2,
          image: 'audience',
          title: 'Real reviews',
          text: 'Just small description to tell your customers what you will see on this page and how it work with crypto bla bla… will see on this'
        },
        {
          id: 3,
          image: 'unicorn',
          title: 'Best Features',
          text: 'Just small description to tell your customers what you will see on this page and how it work with crypto bla bla… will see on this'
        }
      ],
      detailsItems: [
        {
          airdropRewardTokens: '$0.05',
          referalReward: '100%'
        },
        {
          airdropRewardTokens: '$0.05',
          referalReward: '100%'
        },
        {
          airdropRewardTokens: '$0.05',
          referalReward: '100%'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getIsDarkTheme', 'getUserDB'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.about-main {
  display: grid;
  margin-bottom: 44px;
  gap: 60px;

  .about-title {
    margin-bottom: 12px;
    font-size: var(--font-h1);
    text-align: center;

    @include breakpoint(medium) {
      margin-bottom: 20px;
      font-size: var(--font-display);
    }
  }

  .about-title.details-title {
    @include breakpoint(medium) {
      text-align: left;
    }
  }

  .about-subtitle {
    margin-bottom: 28px;
    font-size: var(--font-base);
    text-align: center;
    color: var(--secondary-text-color);

    @include breakpoint(medium) {
      margin-bottom: 40px;
    }
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(medium) {
      justify-content: left;
    }
  }

  @include breakpoint(medium) {
    gap: 86px;
  }
}

.features {
  display: flex;
  flex-direction: column;

  &-cards-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;

    .icon-container {
      min-width: 54px;

      @include breakpoint(medium) {
        min-width: 64px;
      }
    }

    @include breakpoint(medium) {
      grid-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-card {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-default);
    padding: 21px 22px;
    box-shadow: var(--box-shadow-light);
    background-color: var(--content-bg-color);
    gap: 20px;

    .card-title {
      margin-bottom: 10px;
      font-size: var(--font-h4);
    }

    .card-text {
      font-size: var(--font-base);
      color: var(--secondary-text-color);
    }

    @include breakpoint(medium) {
      align-items: start;
      padding: 28px 16px;
    }
  }

  &-card.login-container {
    display: block;
    margin-top: 10px;
    padding: 24px 26px;

    .card-text {
      margin-bottom: 14px;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    ::v-deep .button-text {
      @include breakpoint(medium) {
        padding: 26px;
      }
    }

    @include breakpoint(medium) {
      display: flex;
      justify-self: center;
      max-width: 674px;
    }
  }
}

.casinos {
  &::v-deep .casinos {
    .casino {
      pointer-events: none;
    }

    .casino:first-child {
      filter: blur(4px);
    }

    .casino:last-child {
      filter: blur(4px);

      @include breakpoint(medium) {
        filter: none;
      }
    }

    .action {
      display: none;
    }
  }

  &::v-deep .casinos-header {
    pointer-events: none;
  }
}

.details-card-wrapper {
  margin-bottom: 36px;

  @include breakpoint(medium) {
    margin-bottom: 64px;
  }
}

.overview {
  .content {
    margin-bottom: 62px;

    .about-title {
      @include breakpoint(medium) {
        text-align: left;
      }
    }

    .about-subtitle {
      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }

  .content.airdrop {
    margin-bottom: 30px;
  }

  .airdrop-list {
    display: grid;
    text-align: center;
    gap: 14px;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  .airdrop-item {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-base);
    gap: 20px;
  }

  .airdrop-item::before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: var(--main-bg-color);
  }
}

.mobile {
  @include breakpoint(medium) {
    display: none;
  }
}

.desktop {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

.review-example {
  .img-container {
    @include breakpoint(medium) {
      justify-content: right;
      min-width: 425px;
    }
  }

  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 90px;
  }
}

.airdrop-calendar {
  .img-container {
    @include breakpoint(medium) {
      justify-content: left;
      min-width: 396px;
    }
  }

  @include breakpoint(medium) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: -96px;
    gap: 144px;
  }
}
</style>
