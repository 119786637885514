<template>
  <div class="card">
    <span class="card-title">
      Details
    </span>
    <div class="card-info">
      <div class="info-item">
        <span class="card-info-title">Airdrop reward:</span>
        <span class="card-info-value">{{ item.airdropRewardTokens }}</span>
      </div>
      <div class="info-item">
        <span class="card-info-title">Referral reward:</span>
        <span class="card-info-value">{{ item.referalReward }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsCard',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card {
  @include content;

  min-height: 150px;
}

.card-title {
  display: block;
  margin-bottom: 25px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-h3);
}

.card-info {
  .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--default-border);
    padding: 9px 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .card-info-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .card-info-value {
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }
}
</style>
